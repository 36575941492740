import axiosBase from 'axios';

class HttpUtils {

    /**
    * 画像のプリロード処理
    * @name loadImage
    * @param {string} srcPath
    */
    loadImage (srcPath) {
        let result = new Promise((resolve)=> {
            
            let image = new Image();

            // 画像の読み込み完了時のコールバックを設定
            image.onload = () =>{
                resolve();
            }

            // srcに画像パスを設定することで読み込み処理が実行されます。
            image.src = srcPath;

            // すでに読み込み済であった場合
            if(image.complete){
                resolve();
            }

        });
        return result;
    }

    /**
    * scriptファイルを読み込む
    * @name loadScript
    * @param {string} url
    */
    loadScript  (url) {
        return new Promise( (resolve, reject) => {
            const axios = axiosBase.create({
                responseType: "text"
            });
            axios.get(url).then((response)=>{
                eval(response.data)
                resolve();
            }).catch((err)=>{
                reject(err)
            });
        })
    }

    /**
    * cssファイルを読み込む
    * @name loadCss
    * @param {string} url
    */
    loadCss (url) {
        let link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.href = url;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    /**
     * メタタグを追加する
     * @param {*} params 
     */
    addMetaTag(params){
        let meta  = document.createElement('meta');
        for (let key in params) {
            meta.setAttribute(key, params[key]);
        }
        document.getElementsByTagName('head')[0].appendChild(meta);
    }

    /**
    * URLパラメータを取得する
    * @name getURLParams
    */
    getURLParams (_url) {
        let _paramstr = _url ? _url.split("?")[1] : location.search.replace('?', '');
        let params = {};
        let _arr = _paramstr ? _paramstr.split('&') : [];
        for (let i=0;i<_arr.length;i++){
            let keyval = _arr[i].split('=');
            if(keyval[0]){
                params[decodeURIComponent(keyval[0])] = decodeURIComponent(keyval[1]);
            }
        }
        return params;
    }

    /*
    * URLパラメータを追加する
    */
    addURLParams (_url, _addParams) {
        let params = this.getURLParams(_url);
        for (const [key, value] of Object.entries(_addParams)) {
            params[key] = value;
        }
        return _url.split("?")[0] + this.createURLParamsStr(params);
    }

    /**
    * URLパラメータ文字列を生成する
    * @name createURLParamsStr
    * @param {Object} params
    */
    createURLParamsStr (params) {
        let paramsStr = "";
        let isFirst = true;
        if(params && typeof params === "object"){
            for(let _key of Object.keys(params)) {
                if(isFirst){
                    isFirst= false;
                    paramsStr += '?';
                }else{
                    paramsStr += '&';
                }
                paramsStr += encodeURIComponent(_key) + '=' + encodeURIComponent(params[_key]);
            }
        }
        return paramsStr;
    }

    /**
    * URLパラメータを現在のページに動的に適用する。
    * @name applyURLParamsStr
    * @param {Object} params
    */
    applyURLParamsStr (params){
        history.replaceState('', '', this.getHtmlFileName() + this.createURLParamsStr(params));
    }

    /**
    * キャッシュ対策用の文字列を生成する。
    * @name createCacheParam
    * @param {number} interval キャッシュ対策文字列の更新間隔(ミリ秒)。デフォルト値は1000。
    */
    createCacheParam (interval = 1000) {
        let _d = new Date();
        let _param = Math.floor(_d.getTime()/interval);
        return _param;
    }

    /*
    * キャッシュ対策用として使用するための yyyyMMddss 形式の文字列を生成し、戻す
    */
    createDateStr () {
        let t = new Date();
        let param = t.getFullYear() + 
            ("0" + (t.getMonth() + 1)).slice( -2 ) + 
            ("0" + t.getDate()).slice( -2 ) + 
            ("0" + t.getHours()).slice( -2 ) + 
            ("0" + t.getMinutes()).slice( -2 );
        return param;
    }

    /*
    * サイトのディレクトリパスを戻す。
    */
    getDirectoryPath () {
        return location.pathname.substring(0, location.pathname.lastIndexOf('/')) + '/';
    }

    /*
    * サイトのディレクトリパスを戻す。
    */
    getDirectoryName () {
        return location.pathname.substring(0, location.pathname.lastIndexOf('/')).split("/").pop();
    }

    /*
    * ページのファイル名を戻す。
    */
    getHtmlFileName () {
        return location.href.split("?")[0].split("/").pop();
    }

}

const http_utils = new HttpUtils();
export default http_utils;