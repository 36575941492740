import $ from "jquery"
import axios from "axios"
import HttpUtils from "js_common_lib/lib/HttpUtils"


const check_api_url = "https://qr.qisz-labo.net/check.php";

/* シャッターSE */
let shutter_se;

/* インカメラを使用しているフラグ */
let isInCameraFlg = true;

/* カメラのストリーム */
let curSTREAM = null;


$(async function(){
    console.log("ready.")

    let hush = HttpUtils.getURLParams(location.hrf).h;
    console.log(hush)
    console.log(HttpUtils.addURLParams (check_api_url, {"h":hush}))
    axios.get(HttpUtils.addURLParams (check_api_url, {"h":hush})).then(async (res)=>{
        console.log(res);
        console.log(res.data.status);

        const status_flg = res.data.status;
        // const status_flg = true;

        if(status_flg){
            // ---------------------------------------------------------------------------------------

            // シャッター効果音
            shutter_se = document.querySelector('#shutter_se');

            // ---------------------------------------------------------------------------------------

            // カメラの準備
            let _output = document.querySelector("#shot_image_panel .video_wrap video");
            isInCameraFlg = true;
            // カメラの初期化
            shotImageCamerainit(_output, isInCameraFlg);


            await sleep(500);

            //画面の表示
            dispShotImageScreen(_output);

            // ---------------------------------------------------------------------------------------

            addListener();

            // ---------------------------------------------------------------------------------------
            // ５分後にタイムアウト

            await sleep(5 * 60 * 1000);
            console.log("timeout")

            await hideShotImageScreen();
            
            showAuthErr();

        } else {
            showAuthErr();
        }
    })



})

// ------------------------------------------------

/*
* 撮影画面を表示する
*/
const dispShotImageScreen = async (_output) => {
    return new Promise(async (resolve) => {
        $("#shot_image_panel").on("transitionend", function(){
            $("#shot_image_panel").off("transitionend");
            resolve();
        });

        $("#shot_image_panel").removeClass("notavailable");
        await sleep(50);

        // カメラモードにする
        $("#shot_image_panel").removeClass("photo_mode");

        // 表示
        $("#shot_image_panel").addClass("show");

        // await sleep(1000);

        // カメラの初期化
        // shotImageCamerainit(_output, isInCameraFlg);
    });
}

/*
* 撮影画面のカメラの初期化
*/
const shotImageCamerainit = (_output, _inCameraFlg) => {

    // カメラの設定
    let _width = $(_output).width();
    let _height = $(_output).height();
    let _conf = getCameraConf(_width,_height,_inCameraFlg);

    // カメラを初期化
    cameraInit(_output, _conf);

}

/*
* 撮影画面を非表示にする
*/
const hideShotImageScreen = () => {
    return new Promise((resolve) => {
        $("#shot_image_panel").on("transitionend", function(){
            $("#shot_image_panel").off("transitionend");
            $("#shot_image_panel").addClass("notavailable");
            resolve();
        });
        $("#shot_image_panel").removeClass("show");
    });
}

/*
* 撮影をする
*/
const doShooting = () => {

    // canvas の conext
    const _canvas = document.querySelector("#shot_image_panel .image_area .canvas_wrap canvas");
    const _ctx = _canvas.getContext("2d");

    // 映像を停止
    const _video = document.querySelector("#shot_image_panel .image_area .video_wrap video");
    _video.pause();

    const _frame_img = document.querySelector("#shot_image_panel .image_area .video_wrap .flame_img");

    // シャッター音
    shutter_se.play();
    
    // canvas のサイズ
    let _width = $(_canvas).width();
    let _height = $(_canvas).height();
    _canvas.width =  _width;
    _canvas.height =  _height;
    
    // canvasに画像を貼り付ける
    _ctx.drawImage(_video, 0, 0, _width, _height);
    _ctx.drawImage(_frame_img, 0, 0, _width, _height);

    // canvas を img に変換
    const _photo_img = document.querySelector("#shot_image_panel .image_area .img_wrap img");
    _photo_img.src = _canvas.toDataURL('image/jpeg', 0.85);

    // 表示切り替え
    $("#shot_image_panel").addClass("photo_mode");
}

/*
* 画像を保存する
*/
const saveImg = () => {

    //canvas
    const _canvas = document.querySelector("#shot_image_panel .image_area .canvas_wrap canvas");
    //アンカータグを作成
    let a = document.createElement('a');
    a.href = _canvas.toDataURL('image/jpeg', 0.85);
    //ダウンロード時のファイル名を指定
    a.download = 'download.jpg';
    //クリックイベントを発生させる
    a.click();
}

// ----------------------------------------------------------

const showAuthErr = () => {
    return new Promise(async (resolve) => {
        $("#auth_err_panel").on("transitionend", function(){
            resolve();
        });
        $("#auth_err_panel").removeClass("notavailable");
        await sleep(50);
        $("#auth_err_panel").addClass("show");
    })
}

const hideAuthErr = () => {
    return new Promise(async (resolve) => {
        $("#auth_err_panel").on("transitionend", function(){
            $("#auth_err_panel").addClass("notavailable");
            resolve();
        });
        $("#auth_err_panel").removeClass("show");
    })
}

// ----------------------------------------------------------

/*
* カメラの設定を取得する
*/
const getCameraConf = (_width, _height, _inCameraFlg) => {
    let camera_conf = {
        audio: false,
        video: {
            width: 300,
            height: 200,
            facingMode: "user"   // フロントカメラを利用する
            // facingMode: { exact: "environment" }  // リアカメラを利用する場合
        }
    };
    camera_conf.video.width = _height;
    camera_conf.video.height = _width;

    if(_inCameraFlg){
        camera_conf.video.facingMode = "user";
    }else{
        camera_conf.video.facingMode = { exact: "environment" };
        // camera_conf.video.facingMode = "environment";
    }
    

    // TODO 強制的に外カメラにしてみる
    // camera_conf.video.facingMode = { exact: "environment" };

    return camera_conf;
}

/*
* カメラの初期化
*/
const cameraInit = (_output, _camera_conf) => {

    // 出力するvideo要素の属性設定
    _output.setAttribute('autoplay', '');
    _output.setAttribute('muted', '');
    _output.setAttribute('playsinline', '');


    // すでにカメラと接続していたら停止
    if (curSTREAM !== null) {
        curSTREAM.getVideoTracks().forEach((camera) => {
            camera.stop();
        });
    }
    
    // カメラ
    navigator.mediaDevices.getUserMedia(_camera_conf)
    .then( (stream) => {

        // 前後の切り替え用に保持
        curSTREAM = stream;

        _output.srcObject = stream;
        _output.onloadedmetadata = (e) => {
            // カメラの動画を再生開始
            _output.play();
        };
    })
    .catch( (err) => {
        console.log(err.name + ": " + err.message);
    });
}

// ----------------------------------------------------------

/*
* イベントリスナ登録
*/
const addListener = () =>{

     // 撮影
     $("#shot_image_panel .shutter_btn").on("click", function(){
        doShooting();
    });

    // カメラ切替
    $("#shot_image_panel .camera_switch_btn").on("click", function(){
        let _output = document.querySelector("#shot_image_panel .video_wrap video");
        isInCameraFlg = !isInCameraFlg;
        shotImageCamerainit(_output, isInCameraFlg);
    });

    // ----------

    // 画像を保存する
    $("#shot_image_panel .save_btn").on("click", function(){
        saveImg();
    });

    // 撮り直す
    $("#shot_image_panel .retake_btn").on("click", function(){
        // 表示切り替え
        $("#shot_image_panel").removeClass("photo_mode");
        
        // カメラの初期化
        let _output = document.querySelector("#shot_image_panel .video_wrap video");
        shotImageCamerainit(_output, isInCameraFlg);
    });


}

// ----------------------------------------------------------

/*
* スリープ処理
*/
const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}